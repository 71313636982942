import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FeaturedCaseStudy from '../components/featuredCaseStudy'
import CaseStudyCard from '../components/caseStudyCard'
import Callout from '../components/callout'
import Section from '../components/section'
import PageHeader from '../components/pageHeader'
import * as styles from './styles/work-page.module.css'
import { getTextCopy, getHtmlCopy } from '../helpers'

const Work = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
    callout,
  } = data.workPage
  const featuredCaseStudy =
    data.featuredCaseStudy.edges[0].node.featuredCaseStudy
  const caseStudies = data.caseStudies.edges
  const { caseStudyPageSlug } = data.caseStudyPage

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <Section>
          <PageHeader
            header={getTextCopy(content, 'page.work.header')}
            subheader={getHtmlCopy(content, 'page.work.subheader')}
          />
        </Section>
        <div className={styles.bgWrapper}>
          <Section>
            <FeaturedCaseStudy
              title={featuredCaseStudy.title}
              description={featuredCaseStudy.excerpt}
              linkTo={caseStudyPageSlug.replace(
                `{{slug}}`,
                featuredCaseStudy.slug
              )}
              image={featuredCaseStudy.image.file.url}
              tags={featuredCaseStudy.tags}
            />
          </Section>
        </div>

        <div className={styles.caseStudyCardsWrapper}>
          <Section>
            <h2 className={styles.h2}>
              {getTextCopy(content, 'page.work.moreHeader')}
            </h2>
            <div className={styles.caseStudyCardsGrid}>
              {caseStudies.map(caseStudy => {
                // Don't show the featured case study
                return caseStudy.node.slug !== featuredCaseStudy.slug ? (
                  <CaseStudyCard
                    tags={caseStudy.node.tags}
                    key={caseStudy.node.title}
                    title={caseStudy.node.title}
                    description={caseStudy.node.excerpt}
                    linkTo={caseStudyPageSlug.replace(
                      `{{slug}}`,
                      caseStudy.node.slug
                    )}
                    image={caseStudy.node.image.file.url}
                  />
                ) : null
              })}
            </div>
          </Section>
        </div>
      </Layout>
    </>
  )
}

export default Work

export const query = graphql`
  {
    workPage: contentfulPage(name: { eq: "page.work" }) {
      ...pageFields
    }
    featuredCaseStudy: allContentfulWorkFeaturedCaseStudy {
      edges {
        node {
          featuredCaseStudy {
            title
            excerpt
            slug
            id
            image {
              file {
                url
              }
            }
            tags {
              id
              title
            }
          }
        }
      }
    }
    caseStudies: allContentfulCaseStudy {
      edges {
        node {
          id
          slug
          title
          excerpt
          image {
            file {
              url
            }
          }
          tags {
            id
            title
          }
        }
      }
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      caseStudyPageSlug: slug
    }
  }
`
