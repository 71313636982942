import React from 'react'
import * as styles from './featured-case-study.module.css'
import { Link } from 'gatsby'
import LearnMoreButton from '../../components/learnMoreButton'
import Tag from '../../components/tag'

const FeaturedCaseStudy = ({ title, description, linkTo, image, tags }) => {
  const colorClasses = ['primary', 'secondary', 'tertiary']

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <Link to={linkTo}>
          <h1 className={styles.title}>{title}</h1>
        </Link>
        <p className={styles.description}>{description}</p>
        <div className={styles.tagContainer}>
          {(tags || []).map((tag, i) => (
            <div key={tag.id} className={styles.tag}>
              <Tag className={colorClasses[i % 3]} tag={tag.title} />
            </div>
          ))}
        </div>
        <Link to={linkTo}>
          <LearnMoreButton />
        </Link>
      </div>
      <Link to={linkTo}>
        <img className={styles.image} src={image} alt={`${title} screenshot`} />
      </Link>
    </div>
  )
}

export default FeaturedCaseStudy
