import React from 'react'
import classNames from 'classnames'
import * as styles from './tag.module.css'

const Tag = ({ tag, className }) => {
  const tagClasses = {
    [styles.tag]: true,
    [styles[className]]: className,
  }

  return <div className={classNames(tagClasses)}>{tag}</div>
}

export default Tag
