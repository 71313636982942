import React from 'react'
import * as styles from './case-study-card.module.css'
import LearnMoreButton from '../../components/learnMoreButton/'
import Tag from '../../components/tag'
import Card from '../card'

const CaseStudyCard = ({
  title,
  description,
  image,
  tags,
  linkTo,
  sidebar,
}) => {
  const colorClasses = ['primary', 'secondary', 'tertiary']

  return (
    <Card
      className={styles.caseStudy}
      style={{ flexDirection: sidebar && 'column' }}
      linkTo={linkTo}
    >
      <div
        className={styles.content}
        style={{
          padding: sidebar && 24,
        }}
      >
        <h4
          className={styles.title}
          style={{
            fontSize: 18,
            fontWeight: 500,
            marginBottom: 8,
          }}
        >
          {title}
        </h4>
        <p
          className={styles.description}
          style={{
            fontSize: 14,
            color: '#718096',
            lineHeight: 1.5,
          }}
        >
          {description}
        </p>
        <div className={styles.tagContainer}>
          {(tags || []).map((tag, i) => (
            <div key={tag.id} className={styles.tag}>
              <Tag className={colorClasses[i % 3]} tag={tag.title} />
            </div>
          ))}
        </div>

        <LearnMoreButton className={styles.learnMoreButton} />
      </div>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${image})`,
          order: sidebar && -1,
          height: sidebar && 140,
          marginLeft: sidebar && 0,
          width: sidebar && '100%',
          borderRadius: sidebar && '10px 10px 0 0',
        }}
      />
    </Card>
  )
}

export default CaseStudyCard
